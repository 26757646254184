<template>
  <div class="home">
    <TrickyWords />
  </div>
</template>

<script>
// @ is an alias to /src
import TrickyWords from "@/components/TrickyWords.vue";

export default {
  name: "Home",
  components: {
    TrickyWords,
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}
</style>
