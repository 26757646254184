<template>
  <section
    class="words"
    @keyup.right="next"
    :style="{ backgroundColor: bgColor }"
  >
    <div class="container__card">
      <div class="card" @click="play" :style="{ transform: rotation }">
        <div class="card__id">{{ word.id }}</div>
        <div class="card__word">{{ word.word }}</div>
      </div>
    </div>

    <div class="container__ui">
      <div class="ui">
        <button type="button" class="btn" @click="back">
          <i class="fas fa-arrow-left"></i>
        </button>

        <button type="button" class="btn" @click="random">
          <i class="fas fa-random"></i>
        </button>

        <button type="button" class="btn" @click="next">
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>

      <div class="info">
        <i class="fas fa-info-circle"></i>&nbsp;Tap or click card to hear word.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      bgColor: undefined,
      fgColor: undefined,
      rotation: undefined,
    };
  },
  created() {
    this.setBgColor();
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyboardEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyboardEvent);
  },
  computed: {
    words() {
      const { words } = this.$store.state;
      return words;
    },
    word() {
      return this.words[this.$store.state.index];
    },
  },
  methods: {
    setBgColor() {
      const rand = Math.random() * 360;
      this.bgColor = `hsl(${rand},100%,95%)`;
      this.fgColor = `hsl(${(rand + 180) % 360},100%,50%)`;

      const Max = 2;
      const Min = -Max;

      this.rotation = `rotate(${Math.random() * (Max - Min)}deg)`;
    },
    handleKeyboardEvent(event) {
      const { key } = event;

      //   console.log({ key });

      switch (key) {
        case "ArrowRight":
        case " ":
          this.next();
          break;

        case "ArrowLeft":
          this.back();
          break;

        case "ArrowUp":
          this.play();
          break;

        case "ArrowDown":
          this.random();
          break;

        default:
          break;
      }
    },

    next() {
      this.setBgColor();
      this.$store.state.index =
        this.$store.state.index < this.words.length - 1
          ? this.$store.state.index + 1
          : 0;
    },

    back() {
      this.setBgColor();
      this.$store.state.index =
        this.$store.state.index > 0
          ? this.$store.state.index - 1
          : this.words.length - 1;
    },

    random() {
      this.setBgColor();
      const Max = this.$store.state.words.length - 1;
      const Min = 0;
      const indexNext = Math.floor(Math.random() * (Max - Min));

      this.$store.state.index = indexNext;
    },

    play() {
      const { speechSynthesis } = window;

      if (speechSynthesis) {
        const msg = new SpeechSynthesisUtterance();
        msg.text = this.word.word;
        speechSynthesis.speak(msg);
      } else {
        console.warn("Sorry, your browser doesn't support text to speech!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.words {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s ease-in-out;

  touch-action: none;

  .container__card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 30px;

    margin-top: -10vh;
  }

  .container__ui {
    position: absolute;
    bottom: 3vh;

    display: flex;
    flex-direction: column;

    > * {
      &:not(:last-child) {
        margin-bottom: 2vh;
      }
    }

    .ui {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > * {
        &:not(:last-child) {
          margin-right: 2vw;
        }
      }
    }

    .info {
      user-select: none;
      background-color: #fff;
      border-radius: 0.5vw;
      padding: 0.5vw 1vw;
      font-size: 1.2vw;
    }
  }

  .btn {
    all: unset;
    padding: 0.5vw 1vw;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: bold;
    font-size: 4vw;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.125);
    }
  }

  .card {
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;

    box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.25);

    padding-right: 5vw;

    border-bottom: 0.25vw dashed #ccc;

    cursor: pointer;

    &__id {
      font-size: 2vw;
      line-height: 1;
      width: 10vw;
      color: #999;
    }

    &__word {
      font-size: 8vw;
      line-height: 1;
      padding: 2vh 0;
      transition: width 0.25s ease-in-out;
    }
  }
}

@media screen and (max-width: 1024px) {
  .words {
    .btn {
      padding: 1vw 2vw;
      font-size: 4vw;
    }

    .container__ui {
      .info {
        font-size: 1.5vw;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .words {
    .card {
      border-bottom: 0.75vw dashed #ccc;

      &__id {
        font-size: 4vw;
        width: 14vw;
      }

      &__word {
        font-size: 14vw;
        padding: 2vh 0;
      }
    }

    .container__ui {
      .info {
        padding: 0.5vw 1vw;
        font-size: 4vw;
      }
    }

    .btn {
      padding: 2vw 4vw;
      font-size: 8vw;
    }
  }
}
</style>
